/* eslint-disable prettier/prettier */
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  CButton,
  CCol,
  CContainer,
  CRow,
  CForm,
  CFormLabel,
  CFormInput,
  CCard,
  CCardBody,
  CImage,
} from '@coreui/react-pro'
import axios from 'axios'
import CIcon from '@coreui/icons-react'
import { cilEye, cilEyeSlash } from '@coreui/icons-pro'
import { useDispatch } from 'react-redux'
import { updateUserData, multipleAPICalls } from '../services/redux-store/appSlice'

const validateInputs = (email, password) => {
  const errors = {}
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@!#$%^&*()_+={}\[\]:;"'<>,.?/\\|`~\-]{8,}$/

  if (!email) {
    errors.email = 'Email is required'
  } else if (!emailRegex.test(email)) {
    errors.email = 'Please enter a valid email address'
  }

  if (!password) {
    errors.password = 'Password is required'
  } else if (!passwordRegex.test(password)) {
    errors.password =
      'Password must be at least 8 characters long and include both letters and numbers'
  }

  return errors
}

const Toast = ({ show, message, color }) => {
  if (!show) return null

  return (
    <div
      style={{
        position: 'fixed',
        top: '10px',
        right: '10px',
        backgroundColor: color,
        color: '#fff',
        padding: '10px 20px',
        borderRadius: '5px',
        zIndex: 9999,
      }}
    >
      {message}
    </div>
  )
}

const LoginForm = () => {
  const [toast, setToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [toastColor, setToastColor] = useState('')
  const [formData, setFormData] = useState({ email: '', password: '' })
  const [errors, setErrors] = useState({})
  const [toastData, setToastData] = useState({ show: false, message: '', color: '' })
  const [showPassword, setShowPassword] = useState(false) // State for toggling password visibility
  const history = useHistory()
  const dispatch = useDispatch()
  const handleChange = (e) => {
    const { id, value } = e.target
    setFormData((prev) => ({ ...prev, [id]: value }))
    setErrors((prev) => ({ ...prev, [id]: '' })) // Clear errors on input
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const validationErrors = validateInputs(formData.email, formData.password)
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
      return
    }

    const baseUrl = 'https://dev-api.houeze.com/fsn/api/v1/user/login'

    try {
      const response = await axios.post(baseUrl, formData)

      if (response.data.status_code === 1) {
        localStorage.setItem('menu_permissions', JSON.stringify(response?.data?.menu_permissions))
        localStorage.setItem(
          'user_permissions',
          JSON.stringify(response?.data?.user?.permission?.permissions),
        )
        localStorage.setItem(
          'location_id',
          response?.data?.user?.user?.user_locations[0].location_id,
        )

        localStorage.setItem('token', true)
        dispatch(multipleAPICalls(response))
          .then((result) => {
            var userType = result?.user?.users_data[0]?.user_type
            var checkUserType = userType.includes(userType.find((data) => data === 'internal'))
            if (checkUserType === true) {
              dispatch(updateUserData(result))
              var objAllowData = {
                category_list: true,
                category_add: true,
                category_edit: true,
                category_active: true,
                category_view: true,
                brand_list: true,
                brand_add: true,
                brand_edit: true,
                brand_active: true,
                brand_view: true,
                role_list: true,
                role_add: true,
                role_edit: true,
                role_active: true,
                role_view: true,
                subcategory_list: true,
                subcategory_add: true,
                subcategory_edit: true,
                subcategory_active: true,
                subcategory_view: true,
                store_list: true,
                store_add: true,
                store_edit: true,
                store_active: true,
                store_view: true,
                product_list: true,
                product_add: true,
                product_edit: true,
                product_active: true,
                product_view: true,
                producttype_list: true,
                producttype_add: true,
                producttype_edit: true,
                producttype_active: true,
                producttype_view: true,
                makers_list: true,
                makers_add: true,
                makers_edit: true,
                makers_active: true,
                makers_view: true,
                permission_list: true,
                permission_add: true,
                permission_edit: true,
                permission_active: true,
                permission_view: true,
                package_list: true,
                package_add: true,
                package_edit: true,
                package_active: true,
                package_view: true,
                bundle_list: true,
                bundle_add: true,
                bundle_edit: true,
                bundle_active: true,
                bundle_view: true,
                serviceitem_list: true,
                serviceitem_add: true,
                serviceitem_edit: true,
                serviceitem_active: true,
                serviceitem_view: true,
                facility_list: true,
                facility_add: true,
                facility_edit: true,
                facility_active: true,
                facility_view: true,
                employee_list: true,
                employee_add: true,
                employee_edit: true,
                employee_active: true,
                employee_view: true,
                location_add: true,
              }
              const strObj = JSON.stringify(objAllowData)
              localStorage.setItem('strObj', strObj)
              localStorage.setItem('token', true)
              history.push(`/dashboard`)
            } else {
              setToast(true)
              setToastColor('red')
              setToastMessage('Unauthorized User')
              setTimeout(() => {
                setToast(false)
                setToastMessage('')
                localStorage.clear()
                document.location.href = '/'
              }, 3000)
            }
          })
          .catch((err) => {
            setToast(true)
            setToastColor('red')
            setToastMessage('Unauthorized User')
            setTimeout(() => {
              setToast(false)
              setToastMessage('')
              localStorage.clear()
              document.location.href = '/'
            }, 3000)
          })
        history.push('/dashboard')
      } else {
        setErrors({ form: response.data.message || 'Login failed' }) // Handle non-success responses
      }
    } catch (error) {
      console.error('Error during login:', error)
      setErrors({ form: 'An error occurred while logging in. Please try again.' })
    }
  }

  const handleCancel = () => {
    setFormData({ email: '', password: '' })
    setErrors({})
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <Toast show={toastData.show} message={toastData.message} color={toastData.color} />
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={5}>
            <CCard className="shadow-lg">
              <CCardBody>
                <center>
                  <CImage
                    src="./houeze-1.png"
                    height={75}
                    width={150}
                    className="mb-4"
                    alt="Logo"
                  />
                </center>
                <CForm onSubmit={handleSubmit}>
                  {/* Email Field */}
                  <div className="mb-3">
                    <CFormLabel htmlFor="email">Email Address</CFormLabel>
                    <CFormInput
                      id="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                    />
                    {errors.email && <div className="text-danger mt-2">{errors.email}</div>}
                  </div>

                  {/* Password Field with Eye Icon on Left */}
                  <div className="mb-3 position-relative">
                    <CFormLabel htmlFor="password">Password</CFormLabel>
                    <div className="d-flex">
                      <CButton
                        type="button"
                        color="link"
                        onClick={() => setShowPassword(!showPassword)}
                        style={{
                          position: 'absolute',
                          right: '10px',
                          zIndex: 1,
                        }}
                      >
                        <CIcon icon={showPassword ? cilEyeSlash : cilEye} />
                      </CButton>
                      <CFormInput
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        value={formData.password}
                        onChange={handleChange}
                        placeholder="Enter your password"
                      />
                    </div>
                    {errors.password && <div className="text-danger mt-2">{errors.password}</div>}
                  </div>

                  {/* Submit and Cancel Buttons */}
                  <CRow>
                    <CCol className="d-flex justify-content-end">
                      <CButton size="sm" color="secondary" onClick={handleCancel} className="me-2">
                        Cancel
                      </CButton>
                      <CButton size="sm" color="primary" type="submit">
                        Login
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default LoginForm

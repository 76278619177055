/* eslint-disable prettier/prettier */

import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
export const multipleAPICalls = (data) => async (dispatch) => {
  var Token = localStorage.getItem('jwtToken')
  let reqObj = {
    auth_token: Token,
  }
  // const checkAuthResult = await axios.post(
  //   process.env.REACT_APP_BASE_URL + '/sauth0/api/v1/fb/checkauth',
  //   reqObj,
  // )

  try {
    // const getUserResult = await axios.get(
    //   process.env.REACT_APP_BASE_URL +
    //     `/sauth0/api/v1/user?id=${checkAuthResult?.data?.message?.uid}`,
    // )
    const getUserResult = {
      data: {
        usr_msg: 'Success',
        message: 'User found',
        status_code: 1,
        users_data: [
          {
            user_id: '631c9be025b32041ebaa11ee',
            user_facilities: [
              {
                facility_id: '63342a6667072b001466754e',
                facility_name: 'New Homes',
                roles: [
                  {
                    id: '63342a67441b761dfbd88b2f',
                    role_name: 'Admin',
                    _id: '63342a67441b761dfbd88b32',
                  },
                ],
                units: [
                  {
                    id: '633849195473830014cdc228',
                    unit_name: '1bhk',
                    unit_type: 'Single bedroom',
                    user_type: 'Owner',
                    _id: '6338491a27677221296ec1cc',
                  },
                  {
                    id: '633849195473830014cdc228',
                    unit_name: '1bhk',
                    unit_type: 'Single bedroom',
                    user_type: 'Tenant',
                    _id: '63384a9c27677221296ec1d2',
                  },
                  {
                    id: '633aa618e42f4e0013ebc302',
                    unit_name: '1bhk',
                    unit_type: 'Single bedroom',
                    user_type: 'Owner',
                    _id: '633aa61922d956fedd14deed',
                  },
                ],
                _id: '63342a67441b761dfbd88b31',
              },
              {
                facility_id: '633bdb742e3c9b0014371a98',
                facility_name: 'Demo colony new',
                roles: [
                  {
                    id: '633bdb7573adb3bbc57d2bda',
                    role_name: 'Admin',
                    _id: '633bdb7573adb3bbc57d2be2',
                  },
                ],
                units: [],
                _id: '633bdb7573adb3bbc57d2be1',
              },
              {
                facility_id: '633be0d02e3c9b0014371fa5',
                facility_name: 'Broadway Lofts',
                roles: [
                  {
                    id: '633be0d173adb3bbc57d2c36',
                    role_name: 'Admin',
                    _id: '633be0d173adb3bbc57d2c40',
                  },
                ],
                units: [],
                _id: '633be0d173adb3bbc57d2c3f',
              },
              {
                facility_id: '633bf9032e3c9b00143726a4',
                facility_name: 'demo',
                roles: [
                  {
                    id: '633bf904e4988b4e3928ac63',
                    role_name: 'Admin',
                    _id: '633bf904e4988b4e3928ac6f',
                  },
                ],
                units: [],
                _id: '633bf904e4988b4e3928ac6e',
              },
              {
                facility_id: '63296f8c96565900135912d3',
                facility_name: 'krishi nagar',
                roles: [
                  {
                    id: '63411a70953285d65027fd6c',
                    _id: '63411e7c953285d65027fe3e',
                  },
                ],
                units: [
                  {
                    id: '63411e7c2081480013c90436',
                    unit_name: 'druva homes',
                    unit_type: '3BHK',
                    _id: '63411e7c953285d65027fe3f',
                  },
                ],
                _id: '63411e7c953285d65027fe3d',
              },
              {
                facility_id: '632da97a4ea5b5001423574c',
                facility_name: 'The Den',
                roles: [
                  {
                    id: '636a6d5e9225695e617c8787',
                    _id: '636a6e239225695e617c87a8',
                  },
                ],
                units: [
                  {
                    id: '636a6e23ecfbb20014ee187d',
                    unit_name: 'lotus park',
                    unit_type: '2 bhk',
                    _id: '636a6e239225695e617c87a9',
                  },
                ],
                _id: '636a6e239225695e617c87a7',
              },
              {
                facility_id: '637cd445afa5570014d08416',
                facility_name: 'Gowtham',
                roles: [
                  {
                    id: '637cd4455bac458ec89e3e11',
                    role_name: 'Admin',
                    _id: '637cd4455bac458ec89e3e25',
                  },
                ],
                units: [],
                _id: '637cd4455bac458ec89e3e24',
              },
            ],
            firebase_user: true,
            first_name: 'Ramu P',
            middle_name: null,
            last_name: null,
            mobile: '+918501024517',
            user_locations: [
              {
                location_id: '677956790a3fc30014f3ef0c',
                location_name: 'India',
                parent: ['677956790a3fc30014f3ef0c'],
                child: [
                  '677956e10a3fc30014f3ef14',
                  '677957040a3fc30014f3ef1c',
                  '6779574e0a3fc30014f3ef23',
                  '677957650a3fc30014f3ef2a',
                  '677957810a3fc30014f3ef31',
                  '6779579f0a3fc30014f3ef38',
                  '677957b30a3fc30014f3ef3f',
                  '677957c80a3fc30014f3ef46',
                  '677958200a3fc30014f3ef4d',
                  '677958390a3fc30014f3ef54',
                  '6779587d0a3fc30014f3ef5b',
                  '677958900a3fc30014f3ef62',
                  '677958a20a3fc30014f3ef69',
                  '677959d30a3fc30014f3ef72',
                ],
                roles: [
                  {
                    id: '63233872fa26e7499e3a0e1e',
                    role_name: 'super admin',
                    _id: '632579085a14368dfb101efb',
                  },
                ],
                department: [
                  {
                    id: '631ec835eb7cc20015ba6e1e',
                    department_name: 'Electrical department',
                    _id: '632579085a14368dfb101efd',
                  },
                ],
                _id: '636f61f04dbceb068e1572a0',
              },
            ],
            user_type: ['internal', 'external'],
            user_roles: [
              {
                role_id: '631c1da6bd9e463cb10a8657',
                role_name: 'Admin',
              },
            ],
            _id: '631c9be025b32041ebaa11ee',
          },
        ],
        permissions: {
          bundle_create: true,
          bundle_list: true,
          bundle_search: true,
          bundle_edit: true,
          bundle_view: true,
          bundle_delete: true,
          plan_create: true,
          plan_list: true,
          plan_edit: true,
          plan_search: true,
          plan_view: true,
          plan_delete: true,
          category_create: true,
          category_edit: true,
          category_list: true,
          category_delete: true,
          category_view: true,
          serviceitem_create: true,
          serviceitem_edit: true,
          serviceitem_list: true,
          serviceitem_delete: true,
          serviceitem_view: true,
          department_create: true,
          department_edit: true,
          department_list: true,
          department_delete: true,
          department_view: true,
          facility_create: true,
          facility_edit: true,
          facility_list: true,
          facility_delete: true,
          facility_view: true,
          utility_create: true,
          utility_edit: true,
          utility_list: true,
          utility_delete: true,
          utility_view: true,
          employee_create: true,
          employee_edit: true,
          employee_list: true,
          employee_delete: true,
          employee_view: true,
          location_create: true,
          location_edit: true,
          location_list: true,
          location_delete: true,
          location_view: true,
          menuitem_masters: true,
          menuitem_menu: true,
          menuitem_employee: true,
          menuitem_category: true,
          menuitem_serviceitem: true,
          menuitem_department: true,
          menuitem_store: true,
          menuitem_makers: true,
          menuitem_brand: true,
          menuitem_facility: true,
          menuitem_producttype: true,
          menuitem_product: true,
          menuitem_location: true,
          menuitem_permission: true,
          menuitem_plan: true,
          menuitem_bundle: true,
          menuitem_role: true,
        },
      },
    }
    Object.assign(getUserResult?.data, { menu_permissions1: {} })
    // eslint-disable-next-line array-callback-return
    Object.keys(getUserResult?.data?.permissions).map((data, idx) => {
      if (!!data.match(/menuitem_/gi) === true) {
        Object.assign(getUserResult?.data, {
          menu_permissions1: {
            ...getUserResult?.data?.menu_permissions1,
            [Object.keys(getUserResult?.data?.permissions)[idx]]:
              getUserResult?.data?.permissions[Object.keys(getUserResult?.data?.permissions)[idx]],
          },
        })
      }
    })
    var userDetails
    userDetails = []
    userDetails = getUserResult?.data
    const api_results = { user: userDetails }
    return api_results
  } catch (err) {
    console.log(err)
  }
}
const initial_state = {
  sidebarShow: true,
  asideShow: false,
  theme: 'default',
  userData: [],
}
const appSlice = createSlice({
  name: 'app/data',
  initialState: initial_state,
  userData: [],
  reducers: {
    updateUserData: (state, action) => {
      return { ...state, userData: action.payload }
    },
    updateSidebarShow: (state, action) => {
      return { ...state, sidebarShow: !state.sidebarShow }
    },
  },
})
export const { updateUserData, updateSidebarShow } = appSlice.actions
export default appSlice.reducer

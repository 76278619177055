// import { initializeApp } from 'firebase/app'
// import { getAuth } from 'firebase/auth'

// const firebaseConfig = {
//   apiKey: 'AIzaSyC6n-8rUmoRdspvM8o_WHcl2wnOkOMUMhU',
//   authDomain: 'houeze-web-admin.firebaseapp.com',
//   projectId: 'houeze-web-admin',
//   storageBucket: 'houeze-web-admin.appspot.com',
//   messagingSenderId: '235837830195',
//   appId: '1:235837830195:web:d2d85b4099fd8bf9549f46',
//   measurementId: 'G-PNCCZW7L9X',
// }
// // Initialize Firebase
// const app = initializeApp(firebaseConfig)
// export const auth = getAuth(app)
// export default app

//==================================================================================

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyC6n-8rUmoRdspvM8o_WHcl2wnOkOMUMhU',
  authDomain: 'houeze-web-admin.firebaseapp.com',
  projectId: 'houeze-web-admin',
  storageBucket: 'houeze-web-admin.firebasestorage.app',
  messagingSenderId: '235837830195',
  appId: '1:235837830195:web:d2d85b4099fd8bf9549f46',
  measurementId: 'G-PNCCZW7L9X',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export default app
